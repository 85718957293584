@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 100vw !important;
  }
}
.appA {
  display: grid;
  grid-template-columns: 1fr;

  margin-top: 30px;
}

.Heading {
  justify-self: center;
  font-family: "Roboto Slab", serif;
  font-size: 200%;
  margin: 0px;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
}
.signUp {
  justify-self: center;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 3;
  grid-row-end: 3;
  min-width: 360px;
  max-width: 25%;
  padding: 10px;
}

.signUp > div > p {
  opacity: 0.47;
  text-align: center;
  font-size: 26px;
  margin: 5px;
  font-style: italic;
}
.Heading > h1 {
  margin: 0px;
}

.loginForm {
  justify-self: center;

  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 2;
  min-width: 360px;
  max-width: 25%;
  padding-top: 30px;
  padding-bottom: 30px;
  border: solid 2px black;
  border-radius: 18px;
}
.btnStyle {
  width: 90%;

  margin-right: auto;
  margin-left: auto;
}
.ErrorMessage {
  color: #f33232;
  font-size: 10px;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.ErrorMessage > p {
  text-align: left;
  margin: 0px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.btnStyle > input {
  width: 100%;
  /* Green */
  border: solid 3px black;

  padding: 12px;
  margin-top: 3%;
  border-radius: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 19px;
}

.inputt {
  width: 89%;
  margin-left: auto;
  margin-right: auto;
}

footer {
  color: white;
  display: block;
  text-align: right;
  padding: 10px;
  opacity: 0.47;
  font-size: 20px;
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

footer > p {
  padding: 0px;
  margin: 0px;

  text-align: right;
  margin-right: 20px;
}

.inputt > input {
  width: 99%;
  border: solid 3px black;
  margin-left: auto;
  margin-right: auto;
  height: 25px;
  font-size: 18px;
  border-radius: 10px;
}

.lblStyle {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}
.lblStyle > p {
  text-align: left;
  margin: 0px;
  padding-bottom: 5px;
  padding-top: 5px;
  font-size: 18px;
}

body {
  font-family: "Sanchez";

  padding: 0px;
  margin: 0px;
  border: 0px;
}

.custom-shape-divider-bottom-1611130926 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1611130926 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 238px;
}

.custom-shape-divider-bottom-1611130926 .shape-fill {
  fill: #4a90e2;
}

input {
  font-family: "Sanchez";
}

@media (min-width: 768px) {
  .appA {
    display: grid;
    grid-template-columns: 1fr;

    margin-top: 30px;
  }

  .Heading {
    font-family: "Roboto Slab", serif;
    font-size: 200%;
    margin: 0px;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }
  .signUp {
    justify-self: center;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 3;
    grid-row-end: 3;
    min-width: 360px;
    max-width: 25%;
    padding: 10px;
  }

  .signUp > div > p {
    opacity: 0.47;
    text-align: center;
    font-size: 26px;
    margin: 5px;
    font-style: italic;
  }
  .Heading > h1 {
    margin: 0px;
  }

  .loginForm {
    justify-self: center;

    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
    min-width: 360px;
    max-width: 25%;
    padding-top: 30px;
    padding-bottom: 30px;
    border: solid 2px black;
    border-radius: 18px;
  }
  .btnStyle {
    width: 90%;

    margin-right: auto;
    margin-left: auto;
  }
  .ErrorMessage {
    color: #f33232;
    font-size: 10px;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  .ErrorMessage > p {
    text-align: left;
    margin: 0px;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .btnStyle > input {
    width: 100%;
    /* Green */
    border: solid 3px black;

    padding: 12px;
    margin-top: 3%;
    border-radius: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 19px;
    font-family: "Sanchez";
  }

  .inputt {
    width: 89%;
    margin-left: auto;
    margin-right: auto;
  }

  footer {
    display: block;
    text-align: center;
    padding: 10px;
    opacity: 0.47;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  footer > p {
    padding: 0px;
    margin: 0px;
    text-align: right;
    margin-right: 20px;
  }
  .inputt > input {
    width: 99%;
    border: solid 3px black;
    margin-left: auto;
    margin-right: auto;
    height: 25px;
    font-size: 18px;
    border-radius: 10px;
  }

  .lblStyle {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
  .lblStyle > p {
    text-align: left;
    margin: 0px;
    padding-bottom: 5px;
    padding-top: 5px;
    font-size: 18px;
  }

  body {
    font-family: "Sanchez";

    padding: 0px;
    margin: 0px;
    border: 0px;
  }
}
