.loader-container{
    position: fixed;
    top: 0;
    left: 0;
    width:  100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
}

.loader{
    top: 25%;
    left: 49%;
    width: 100%;
    z-index: 1000;
    position: absolute;
}